import React, { useState, useEffect, useLayoutEffect } from "react";

const KeepAlive = (defaultprops) => {
  const { globalUrl, isLoggedIn, isLoaded, userdata } = defaultprops;

  const [data, setData] = React.useState([]);
  const [update, setUpdate] = React.useState(0);

	//const workflowId = "f7325514-e75a-49b0-84bd-b82127b40080"
	const workflowId = "7340c39f-dd1d-4635-8085-f4c046e2f770"

	const onChunkedResponseComplete = (result) => {
		  console.log('all done!', result)
	}

	const onChunkedResponseError = (err) => {
		  console.error(err)
	}

	const processChunkedResponse = async (response) => {
		console.log("In process resp!")

		var text = '';
		var reader = response.body.getReader()
		var decoder = new TextDecoder();
		
		const appendChunks = (result) => {
			var chunk = decoder.decode(result.value || new Uint8Array, {stream: !result.done});
			console.log("Got chunk: ", chunk)
			data.push(chunk)
			setData(data)
    	setUpdate(Math.random());

			console.log('got chunk of', chunk.length, 'bytes. Value: ', chunk)
			text += chunk;
			//console.log('text so far is', text.length, 'bytes\n');
			if (result.done) {
				console.log('returning')
				return text;
			} else {
				return readChunk()
			}
		}

		const readChunk = () => {
			return reader.read().then(appendChunks);
		}

		return readChunk();
	}

	async function fetchWithTimeout(resource, options = {}) {
	  const { timeout = 8000 } = options;

	  const controller = new AbortController();
	  const id = setTimeout(() => controller.abort(), timeout);

	  const response = await fetch(resource, {
		...options,
		signal: controller.signal
	  });
	  clearTimeout(id);

	  return response;
	}

	const startWorkflowStream = async () => {
		const timeout = 10000

		while (true) {	
			await fetchWithTimeout(`${globalUrl}/api/v1/workflows/${workflowId}/stream`, {
			  method: "GET",
			  headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			  },
			  credentials: "include",
			  timeout: timeout, 
			})
			.then(processChunkedResponse)
			.then(onChunkedResponseComplete)
			.catch(onChunkedResponseError)
		}
  	}

    useEffect(() => {
		console.log("FIRST REQUEST")
		startWorkflowStream()
	}, [])

	return (
		<div>
			Data after:	
			<br />

			{data.map((innerdata, index) => { 
				return (
					<div key={index}>
						{innerdata}
					</div>
				)
			})}

			<br />
			Data before this
		</div>
	)
}

export default KeepAlive;
