import React, {useState} from 'react';
import theme from '../theme.jsx';

import {
	Paper,
	Typography,
	IconButton,
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const FAQList = (props) => {
	const { question, answer, isHomePage, setIsHomePage, isPriceing } = props

	const [isExpanded, setIsExpanded] = useState(false)
	const iconStyle = {
		color: "white",
	}

	return (
		<div style={{
			// borderRadius: theme.palette.borderRadius, 
			// backgroundColor: "#1A1A1A",
            color: "var(--White-text, #F1F1F1)",
			cursor: isExpanded ? "default" : "pointer",
			margin: 5,
			padding: isPriceing ? 20:10,
			position: "relative",
			textAlign:"left",
			marginBottom: isExpanded ? 10 : isPriceing ?  0 : 16,
		}} onClick={() => {
			if (!isExpanded) {
				setIsExpanded(!isExpanded)	
			}
		}}>
			<IconButton style={{position: "absolute", right: 10, top: isPriceing ? 12 : 3, margin: 0, }} >
				{isExpanded ? 
					<RemoveIcon style={iconStyle}  onClick={() => {
						setIsExpanded(false)
					}}/>
					: 
					<AddIcon style={iconStyle}  onClick={() => {
						setIsExpanded(true)
					}}/>
				}
			</IconButton>
			<Typography variant="h6" style={{cursor:"pointer"}} onClick={()=> isExpanded ? setIsExpanded(false) : setIsExpanded(true)}>
				{question}
			</Typography>
			{isExpanded ? 
				<div onClick={(event) => {
					event.preventDefault()	
				}} style={{backgroundColor: theme.palette.surfaceColor, padding: 14, marginTop: 15, borderRadius: theme.palette.borderRadius, }}>
					<Typography variant="h6" style={{fontSize:17}} color="textSecondary">
						{answer}
					</Typography>
				</div>
			: null}
		</div>
	)
}

export default FAQList;
