import React, { useEffect } from "react";
import theme from "../theme.jsx";

import { Container, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";


import { LineChart, LineSeries, BarChart } from "reaviz";
import { GridStripe } from "reaviz";
import { PieChart, PieArcSeries } from "reaviz";


import {
	AccountTree as AccountTreeIcon,
	Extension as ExtensionIcon,
	SmsFailed as SmsFailedIcon,
	ViewWeek as ViewWeekIcon,
	DateRange as DateRangeIcon,
	CalendarMonth as CalendarMonthIcon,
	FilterAlt as FilterAltIcon,
	GetApp as GetAppIcon,
} from "@mui/icons-material";

//import "react-circular-progressbar/dist/styles.css";
import {
  Card, 
  CardContent, 
  Typography,
  Tooltip,
  Button,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
} from "@mui/material";

const data = [
  {
    key: new Date("2022-11-18"),
    data: 3,
  },
  {
    key: new Date("2022-11-19"),
    data: 5,
  },
  {
    key: new Date("2022-11-20"),
    data: 4,
  },
  {
    key: new Date("2022-11-21"),
    data: 2,
  },
  {
    key: new Date("2022-11-22"),
    data: 1,
  },
];


const sortByDate = (data) => {
    return data.map((obj) => {
      // convert the value of the "key" property to a Date object
      obj["key"] = new Date(obj["key"]);
      return obj;
    }).sort((a, b) => {
      // sort the array by the Date objects in the "key" property
      return a["key"] - b["key"];
    });
  };
  
const clicksOverTime = (data) => {
    // console.log("clicks input data-->>",data)
    if (data["app_stats"] === undefined || data["app_stats"] === null) {
        return [];
      } else {
        var result = [];
        var maxLength = data["app_stats"].length;
        if (maxLength > 5) {
          var maxLength = 5;
        }
        for (let i = 0; i < maxLength; i++) {
          let obj = data["app_stats"][i];
        
          if ("events" in obj && obj["events"].length > 1) {
            // console.log("inside if printing clicks", obj["events"][1])}
                
            if (obj["events"][1]["data"] != null){
                for (let j = 0; j < obj["events"][1]["data"].length; j++) {
                    let clickEvents = obj["events"][1]["data"][j]
                    result.push({ "key": clickEvents["key"], "data": clickEvents["data"] })
                } 
                } else {}
            }
            }
            
            if (result.length > 20){
                result = result.slice(-20)
                
                const dataWithDates = result.map((obj) => {
                    obj["key"] = new Date(obj["key"]);
                    return obj;
                  });
                //   console.log("result in overtime",dataWithDates)
                  var dataWithDates1 = sortByDate(dataWithDates)
                return dataWithDates1
            }
             else {
                const dataWithDates = result.map((obj) => {
                    obj["key"] = new Date(obj["key"]);
                    return obj;
                  });
                //   console.log("result in overtime",dataWithDates)
                  var dataWithDates1 = sortByDate(dataWithDates)
                return dataWithDates1
            }
        }
      };
      

const conversionsOverTime = (data) => {
        // console.log("clicks input data-->>",data)
        if (data["app_stats"] === undefined || data["app_stats"] === null) {
            return [];
          } else {
            var result = [];
            var maxLength = data["app_stats"].length;
            if (maxLength > 5) {
              var maxLength = 5;
            }
            for (let i = 0; i < maxLength; i++) {
              let obj = data["app_stats"][i];
            
              if ("events" in obj && obj["events"].length > 1) {
                    
                if (obj["events"][0]["data"] != null){
                    for (let j = 0; j < obj["events"][0]["data"].length; j++) {
                        let clickEvents = obj["events"][0]["data"][j]
                        result.push({ "key": clickEvents["key"], "data": clickEvents["data"] })
                    } 
                    } else {}
                }
                }
                
                if (result.length > 20){
                    result = result.slice(-20)
                    
                    const dataWithDates = result.map((obj) => {
                        obj["key"] = new Date(obj["key"]);
                        return obj;
                      });
                    //   console.log("result in overtime",dataWithDates)
                      var dataWithDates1 = sortByDate(dataWithDates)
                    return dataWithDates1
                }
                 else {
                    const dataWithDates = result.map((obj) => {
                        obj["key"] = new Date(obj["key"]);
                        return obj;
                      });
                    //   console.log("result in overtime",dataWithDates)
                      var dataWithDates1 = sortByDate(dataWithDates)
                    return dataWithDates1
                }
            }
          };

const appTotalClick = (data) => {
  if (data["app_stats"] === undefined || data["app_stats"] === null) {
    return [];
  } else {
    var result = [];
    var maxLength = data["app_stats"].length;
    if (maxLength > 5) {
      var maxLength = 5;
    }
    for (let i = 0; i < maxLength; i++) {
      let obj = data["app_stats"][i];
      result.push({ key: obj["app_name"], data: obj["total_clicks"] });
    }
    return result;
  }
};

const appTotalConversion = (data) => {
  if (data["app_stats"] === undefined || data["app_stats"] === null) {
    return [];
  } else {
    var result = [];
    var maxLength = data["app_stats"].length;
    if (maxLength > 5) {
      var maxLength = 5;
    }
    for (let i = 0; i < maxLength; i++) {
      let obj = data["app_stats"][i];
      result.push({ key: obj["app_name"], data: obj["total_conversions"] });
    }
    return result;
  }
};

const appTotalSearch = (data) => {
  if (data["app_stats"] === undefined || data["app_stats"] === null ) {
    return [];
  } else {
    var result = [];
    var maxLength = data["app_stats"].length;
    if (maxLength > 5) {
      var maxLength = 5;
    }
    for (let i = 0; i < maxLength; i++) {
      let obj = data["app_stats"][i];
      result.push({ key: obj["app_name"], data: obj["total_searches"] });
    }
    return result;
  }
};

const parseTableData = (data) => {
	const maxApps = 100 

  if (data["app_stats"] === undefined || data["app_stats"] === null) {
    return [];
  } else {
    var result = [];
    var maxLength = data["app_stats"].length;
    if (maxLength > maxApps) {
      var maxLength = maxApps;
    }

    for (let i = 0; i < maxLength; i++) {
      let obj = data["app_stats"][i];
      result.push({
        name: obj["app_name"],
        totalSearches: obj["total_searches"],
        totalClicks: obj["total_clicks"],
        totalConversions: obj["total_conversions"],
      });
    }
    return result;
  }
};

function writeAndDownloadJSON(jsonData, fileName) {
  const jsonString = JSON.stringify(jsonData);

  const jsonBlob = new Blob([jsonString], { type: "application/json" });

  const link = document.createElement("a");

  link.href = URL.createObjectURL(jsonBlob);

  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    minWidth: 75,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const StatisticalData = (props) => {
  const { globalUrl, creatorName } = props;
  const classes = useStyles();
  // const classes1 = useStyles1();

  const [stats, setStats] = React.useState({});
  const [tableData, setTableData] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [clickGraphData, setClickGraphData] = React.useState();
  const [conversionGraphData, setConversionGraphData] = React.useState();

  const surfaceColor = "#27292D";

  const processClicks = (data) => {
    return data.reduce((result, obj) => {
      // check if the "key" value already exists in the "result" array
      let existingObj = result.find((r) => r["key"] === obj["key"]);
  
      // if the "key" value already exists, add the "data" property of the
      // current object to the existing object
      if (existingObj !== undefined) {
        existingObj["data"] += obj["data"];
      } else {
        // if the "key" value does not exist, add the current object to the
        // "result" array
        result.push(obj);
      }
    //   console.log("result in process clicks")
    //   setClickGraphData(result)
    //   return result;
    }, []);
  };

  function getDateBeforeDays(days) {
    var today = new Date();
    var dateBefore30Days = new Date(
      today.getTime() - parseInt(days) * 24 * 60 * 60 * 1000
    );
    return dateBefore30Days.toISOString().split("T")[0];
  }

  const getStaticData = (creator, from) => {
    fetch(
      `${globalUrl}/api/v1/users/creators/${creator}/stats?start_date=${from}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          console.log("Status not 200 for creator stats :O!");
        }
        return response.json();
      })
      .then((responseJson) => {
        setStats(responseJson);
      })
      .catch((error) => {
        console.log(" Error: " + error.toString());
      });
  };

  useEffect(() => {
    var defaultTimeRange = getDateBeforeDays(90);
    getStaticData(creatorName, defaultTimeRange);
    setClickGraphData(clicksOverTime(stats)) 
    setConversionGraphData(conversionsOverTime(stats)) 
  }, []);

  useEffect(() => {
    setTableData(parseTableData(stats));
    var clicksData = clicksOverTime(stats)
    setClickGraphData(clicksData)
    // console.log("clickgraphdata", clickGraphData)

    var conversionData = conversionsOverTime(stats)
    setConversionGraphData(conversionData)
  }, [stats]);

  const dateRangeModal = modalOpen ? (
    <Dialog
      open={modalOpen}
      onClose={() => {}}
      PaperProps={{
        style: {
          backgroundColor: surfaceColor,
          color: "white",
          minWidth: 500,
          padding: 30,
        },
      }}
    >
      <DialogTitle style={{ marginBottom: 0 }}>
        <div style={{ textAlign: "center", color: "rgba(255,255,255,0.9)" }}>
          Select a date range
        </div>
      </DialogTitle>
      <DialogContent
        style={{ color: "rgba(255,255,255,0.65)", textAlign: "center" }}
      >
        <Button
          variant="outlined"
          color="secondary"
          style={{
            height: 35,
            margin: "auto",
            marginLeft: 25,
          }}
          onClick={() => {
            var sevenDay = getDateBeforeDays(7);
            getStaticData(creatorName, sevenDay);
            setModalOpen(false);
            // console.log(sevenDay);
          }}
        >
          <ViewWeekIcon style={{ width: 25, height: 20 }} />
          Last 7 Days
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          style={{
            height: 35,
            margin: "auto",
            marginLeft: 25,
          }}
          onClick={() => {
            var thirtyDays = getDateBeforeDays(30);
            getStaticData(creatorName, thirtyDays);
            setModalOpen(false);
            // console.log(thirtyDays);
          }}
        >
          <DateRangeIcon style={{ width: 25, height: 20 }} />
          Last 30 Days
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          style={{
            height: 35,
            margin: "auto",
            marginLeft: 25,
          }}
          onClick={() => {
            var ninetyDays = getDateBeforeDays(90);
            getStaticData(creatorName, ninetyDays);
            setModalOpen(false);
            // console.log(ninetyDays);
          }}
        >
          <CalendarMonthIcon style={{ width: 25, height: 20 }} />
          Last 90 Days
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          style={{}}
          onClick={() => {
            setModalOpen(false);
          }}
          color="white"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

  return (
    <Container>
      <Grid>
        <div
          style={{
            borderRadius: theme.palette.borderRadius,
            display: "flex",
            width: "700px",
          }}
        ><Typography variant="h6" style={{ marginBottom: 10 }}>
        Statistics
      </Typography>
      <div style={{marginLeft: "270px"}}>
	  	{/*
          <Button
            variant="outlined"
            color="secondary"
            style={{
              height: 35,
              margin: "auto",
              marginLeft: 100,
            }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <FilterAltIcon olor="white" style={{ width: 25, height: 25 }} />{" "}
            Date range
          </Button>
		  */}
          <Tooltip
            title="Export Statistics Data"
            placement="top"
            style={{ zIndex: 10011 }}
          >
            <Button
              variant="outlined"
              color="secondary"
              style={{
                height: 35,
                margin: "auto",
                marginLeft: 25,
              }}
              onClick={() => {
                var fileName = `${stats["creator"]}-stats.json`;
                writeAndDownloadJSON(stats, fileName);
              }}
            >
              <GetAppIcon style={{ width: 25, height: 20 }} />
            </Button>
            
          </Tooltip>
          </div>
        </div>
        {dateRangeModal && dateRangeModal}
      </Grid>
      {/* <Grid>
                <Grid item xl={8} style={{ border: "20px", marginTop: "80px", marginBottom: "15rm", marginTop: "25rm" }}>
                    <center style={{ marginTop: "50px", marginBottom: "30px" }}>
                    <img style={{height: 74, width: 74, borderRadius: 100, }} alt={"Creator profile of "+creatorName(appData)} src={creImg(appData)} />
                        <Typography type="title" variant="h1" color="inherit">
                            Hello, {creatorName(appData)}!
                        </Typography>
                    </center>
                    <center>
                        <Typography type="title" variant="h1" color="inherit">
                            Dashboard
                        </Typography>
                        <div style={{
                            "paddingLeft": "50px"
                        }}>
                            <FormControl className={classes1.formControl}>
                                <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </center>
                </Grid>
                <Divider />
            </Grid> */}
      <Grid
        container
        spacing={3}
        style={{
          width: "750px",
          padding: "5px",
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        <Grid item xs={4}>
          <Card
            className={classes.root}
            style={{
              color: "white",
              backgroundColor: "RGB(31, 32, 36)",
              height: "80px",
              border: "2px solid rgb(255,255,255,0.3)",
            }}
          >
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h5"
                gutterBottom
                style={{ display: "flex" }}
              >
                <AccountTreeIcon style={{ marginRight: "1rem" }} />
                Total Apps
              </Typography>
              <Typography
                variant="h5"
                className={classes.pos}
                color="textSecondary"
              >
                {stats ? stats["apps"] : "Loading.."}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            className={classes.root}
            style={{
              color: "white",
              backgroundColor: "RGB(31, 32, 36)",
              height: "80px",
              border: "2px solid rgb(255,255,255,0.3)",
            }}
          >
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h5"
                gutterBottom
                style={{ display: "flex" }}
              >
                {" "}
                <ExtensionIcon style={{ marginRight: "1rem" }} />
                Total Workflows
              </Typography>
              <Typography
                variant="h5"
                className={classes.pos}
                color="textSecondary"
              >
                {stats ? stats["workflows"] : "Loading.."}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            className={classes.root}
            style={{
              color: "white",
              backgroundColor: "RGB(31, 32, 36)",
              height: "80px",
              border: "2px solid rgb(255,255,255,0.3)",
              minWidth: 200,
              maxWidth: 200,
            }}
          >
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h5"
                gutterBottom
                style={{ display: "flex" }}
              >
                <SmsFailedIcon style={{}} />
                Specialized Apps
              </Typography>
              <Typography
                variant="h6"
                className={classes.pos}
                color="textSecondary"
              >
                Not available
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid style={{ display: "flex", textAlign: "center" }}>
        <Grid
          spacing={2}
          container
          style={{
            maxWidth: "300px",
            borderRadius: theme.palette.borderRadius,
            color: "white",
            backgroundColor: "RGB(31, 32, 36)",
            padding: 20,
            margin: "20px 20px 20px 0px",
          }}
        >
          <Typography
            style={{ margin: "center" }}
            className={classes.title}
            type="title"
            variant="h6"
            color="inherit"
          >
            Total Clicks
          </Typography>
          <Divider style={{ color: "white", marginTop: 5, marginBottom: 5 }} />
          {/* <CircularProgressbar value={click} text={`${click}`}  /> */}
          <PieChart
            height={250}
            width={400}
            key={stats}
            data={appTotalClick(stats)}
          />
        </Grid>
        {/* <Grid container
                        spacing={1}
                        style={{
                            maxWidth: "250px",
                            borderRadius: "25px",
                            margin: "auto auto 10px",
                            color: "white",
                            backgroundColor: "rgb(39, 41, 45)",
                            padding: "20px",
                            textAlign: "center",
                        }}>
                        <Typography style={{ margin: "center" }} type="title" variant="h6" color="inherit">
                            Apps
                        </Typography>
                    </Grid>
                    <Grid container
                        spacing={1}
                        style={{
                            maxWidth: "250px",
                            borderRadius: "25px",
                            margin: "auto auto 10px",
                            color: "white",
                            backgroundColor: "rgb(39, 41, 45)",
                            padding: "20px",
                        }}>
                        <CircularProgressbar value={apps} text={`${apps}`} />
                    </Grid> */}
        <Grid
          container
          spacing={3}
          style={{
            maxWidth: "300px",
            borderRadius: theme.palette.borderRadius,
            color: "white",
            backgroundColor: "RGB(31, 32, 36)",
            padding: 20,
            margin: 20,
          }}
        >
          <Typography
            style={{ margin: "center" }}
            className={classes.title}
            type="title"
            variant="h6"
            color="inherit"
          >
            Total Conversions
          </Typography>
          <Divider style={{ color: "white", marginTop: 5, marginBottom: 5 }} />
          {/* <CircularProgressbar value={click} text={`${click}`}  /> */}
          <PieChart
            height={250}
            width={400}
            key={stats}
            data={appTotalConversion(stats)}
          />
        </Grid>
        {/* <Grid container
                        spacing={1}
                        style={{
                            maxWidth: "250px",
                            borderRadius: "25px",
                            margin: "auto auto 10px",
                            color: "white",
                            backgroundColor: "rgb(39, 41, 45)",
                            padding: "20px",
                        }}>
                        <Typography style={{ margin: "center" }} type="title" variant="h6" color="inherit">
                            WorkFlow
                        </Typography>
                    </Grid>
                    <Grid container
                        spacing={1}
                        style={{
                            maxWidth: "250px",
                            borderRadius: "25px",
                            margin: "auto auto 10px",
                            color: "white",
                            backgroundColor: "rgb(39, 41, 45)",
                            padding: "20px",
                        }}>
                        <CircularProgressbar value={workflow} text={`${workflow}`} />
                    </Grid> */}
        <Grid
          container
          spacing={3}
          style={{
            maxWidth: "300px",
            borderRadius: theme.palette.borderRadius,
            color: "white",
            backgroundColor: "RGB(31, 32, 36)",
            padding: 20,
            margin: 20,
          }}
        >
          <Typography
            style={{ margin: "center" }}
            className={classes.title}
            type="title"
            variant="h6"
            color="inherit"
          >
            Total Searches
          </Typography>
          <Divider style={{ color: "white", marginTop: 5, marginBottom: 5 }} />
          {/* <CircularProgressbar value={click} text={`${click}`}  /> */}
          <PieChart
            height={250}
            width={400}
            key={stats}
            data={appTotalSearch(stats)}
          />
        </Grid>
        {/* <Grid container
                        spacing={1}
                        style={{
                            maxWidth: "250px",
                            borderRadius: "25px",
                            margin: "auto auto 10px",
                            color: "white",
                            backgroundColor: "rgb(39, 41, 45)",
                            padding: "20px",
                        }}>
                        <Typography style={{ margin: "center" }} type="title" variant="h6" color="inherit">
                            Executions
                        </Typography>
                    </Grid>
                    <Grid container
                        spacing={1}
                        style={{
                            maxWidth: "250px",
                            borderRadius: "25px",
                            margin: "auto auto 10px",
                            color: "white",
                            backgroundColor: "rgb(39, 41, 45)",
                            padding: "20px",
                        }}>
                        <CircularProgressbar value={executions} text={`${executions}`} />
                    </Grid> */}

        {/* <Grid style={{ margin: "15px" }}>
                    <Grid container
                        spacing={1}
                        style={{
                            maxWidth: "300px",
                            borderRadius: "25px",
                            margin: "auto auto 10px",
                            color: "white",
                            backgroundColor: "rgb(39, 41, 45)",
                            padding: "20px",
                        }}>
                        <Typography style={{ margin: "center" }} type="title" variant="h6" color="inherit">
                            Total Clicks
                        </Typography>
                        <Divider style={{ color: "white", marginTop: 5, marginBottom: 5 }} />
                        <CircularProgressbar value={click} text={`${click}`}  /> 
                        <PieChart
                            height={250}
                            width={400}
                            data={[
                                { key: 'App_1', data: 13 },
                                { key: 'App_2', data: 24 },
                                { key: 'App_3', data: 5 },
                            ]}
                        />
                </Grid> */}
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          width: "750px",
          color: "white",
          backgroundColor: "rgb(39, 41, 45)",
          textAlign: "center",
          padding: 20,
          marginTop: 20,
        }}
      >
		{/*
        <Grid item md={6}>
        <Typography
            type="Bar chart"
            variant="h6"
            color="inherit"
            style={{ marginBottom: 10 }}
          >
            Conversions over time
          </Typography>
          <LineChart
            width={300}
            height={250}
            data={conversionGraphData}
            key = {conversionGraphData}
            line={<GridStripe fill={"a#393c3e"} />}
            series={<LineSeries symbols={null} />}
          />
        </Grid>
        <Grid item md={6}>
          <Typography
            type="Bar chart"
            variant="h6"
            color="inherit"
            style={{ marginBottom: 10 }}
          >
            Clicks over time
          </Typography>
          <LineChart
            width={300}
            height={250}
            data={clickGraphData}
            key = {clickGraphData}
            line={<GridStripe fill={"a#393c3e"} />}
            series={<LineSeries symbols={null} />}
          />
        </Grid>
		  */}
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          maxWidth: "350px",
          color: "white",
          backgroundColor: "rgb(39, 41, 45)",
          padding: "20px",
        }}
      >
        <Grid item md={12}>
          <TableContainer
            component={Paper}
            style={{
              color: "white",
              backgroundColor: "rgb(31 32 36)",
              width: "700px",
            }}
          >
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Apps Name</TableCell>
                  <TableCell align="right">Total Searches</TableCell>
                  <TableCell align="right">Total Clicks</TableCell>
                  <TableCell align="right">Total Conversions</TableCell>
                </TableRow>
              </TableHead>
              {tableData && (
                <TableBody>
                  {tableData.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.totalSearches}</TableCell>
                      <TableCell align="right">{row.totalClicks}</TableCell>
                      <TableCell align="right">
                        {row.totalConversions}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StatisticalData;
