import React, { useState, useEffect } from "react";

import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import {
	ArrowBack as ArrowBackIcon 
} from '@mui/icons-material';
import { useTheme } from "@mui/styles";
import { toast } from 'react-toastify';

const CreatorEdit = (defaultprops) => {
  const { globalUrl, isLoaded, userdata, setUserData } = defaultprops;
  const theme = useTheme();
  let navigate = useNavigate();
  
  const params = useParams();
  var props = JSON.parse(JSON.stringify(defaultprops))
  props.match = {}
  props.match.params = params

  const [description, setDescription] = useState("");
  const [profile_url, setProfileUrl] = useState("");
  const [location, setLocation] = useState("");
  const [skills, setSkills] = useState("");
  const [workStatus, setWorkStatus] = useState("");
  const [socialLinks, setSocialLinks] = useState("");

  const [creatorProfile, setCreatorProfile] = React.useState({});
  const [actionImageList, setActionImageList] = React.useState([]);

  // Used for error messages etc
  const [passwordFormMessage, setPasswordFormMessage] = useState("");
  const [userSettings, setUserSettings] = useState({});

  //Returns the value from a storage position at a given address.
  const isCloud =
    window.location.host === "localhost:3002" ||
    window.location.host === "shuffler.io"

  const bodyDivStyle = {
    margin: "auto",
    textAlign: "center",
    width: "1100px",
  };

  const boxStyle = {
    flex: "1",
    color: "white",
    position: "relative",
    marginLeft: "10px",
    marginRight: "10px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "30px",
    paddingTop: "30px",
    backgroundColor: theme.palette.surfaceColor,
    display: "flex",
    flexDirection: "column",
  };

  const checkOwner = (data, userdata) => {
    var currentOwner = false;
    if (data.owner.address === userdata.eth_info.account) {
      currentOwner = true;
    } else {
      if (
        data.top_ownerships !== undefined &&
        data.top_ownerships !== null &&
        data.top_ownerships.length === 1
      ) {
        for (var key in data.top_ownerships) {
          if (
            data.top_ownerships[key].owner.address === userdata.eth_info.account
          ) {
            currentOwner = true;
            break;
          }
        }
      }
    }

    return currentOwner;
  };

  const getUserProfile = (username) => {
    fetch(`${globalUrl}/api/v1/users/creators/${username}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("Status not 200 for WORKFLOW EXECUTION :O!");
        }
        return response.json();
      })
      .then((responseJson) => {
        setCreatorProfile(responseJson)

        if (responseJson.specialized_apps !== undefined && responseJson.specialized_apps !== null && responseJson.specialized_apps.length > 0) {
          setActionImageList(responseJson.specialized_apps)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const getSettings = () => {
    fetch(globalUrl + "/api/v1/getsettings", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("Status not 200 for WORKFLOW EXECUTION :O!");
        }


        return response.json();
      })
      .then((responseJson) => {
        setUserSettings(responseJson);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSettings();
    getUserProfile(props.match.params.key)

  }, []);
  const handleClick = (username) => {
    // 👇️ replace set to true
    navigate(`/creators/${creatorProfile.github_username}`, {replace: true});
  };
  const ParsedWorkflowView = (props) => {
    const { data } = props;

    var innerPaperStyle = {
      backgroundColor: theme.palette.inputColor,
      display: "flex",
      flexDirection: "column",
      padding: "0px 0px 12px 0px",
      borderRadius: theme.palette.borderRadius,
    };

    const currentOwner = checkOwner(data, userdata);
    if (currentOwner === true) {
      innerPaperStyle.border = "3px solid #f86a3e";
    }

    return (
      <Grid item xs={4} style={{ borderRadius: theme.palette.borderRadius }}>
        <Paper style={innerPaperStyle}>
          <img
            src={data.image}
            alt={data.name}
            style={{ width: "100%", marginBottom: 10 }}
          />
          <Typography variant="body2" color="textSecondary">
            {data.collection}
          </Typography>
          <Typography variant="body2">{data.name}</Typography>
        </Paper>
      </Grid>
    );
  };


  const runFlex = userdata.eth_info !== undefined && userdata.eth_info.account !== undefined &&
    userdata.eth_info.account.length > 0 && userdata.eth_info.parsed_balance !== undefined

  // Random names for type & autoComplete. Didn't research :^)
  //var imageData = file.length > 0 ? file : fileBase64;
  //imageData = imageData === undefined || imageData.length === 0
  //    ? theme.palette.defaultImage
  //    : imageData;

  const imageData = userSettings.image === undefined || userSettings.image == null || userSettings.image.length === 0 ? theme.palette.defaultImage : userSettings.image
  const imageInfo = (
    <img
      src={imageData}
      alt="Click to upload an image (174x174)"
      id="logo"
      onClick={() => {
        if (imageData !== theme.palette.defaultImage) {
          navigate(`/creators/${userdata.public_username}`)
        } else {
          navigate(`/creators`)
        }
      }}
      style={{
        cursor: "pointer",
        maxWidth: 100,
        maxHeight: 100,
        minWidth: 100,
        minHeight: 100,
        position: "absolute",
        top: -80,
        left: 1020 / 2 - 25,
        borderRadius: 50,
        objectFit: "contain",
        border: "2px solid rgba(255,255,255,0.7)",
      }}
    />
  );

  const setUser = (userId, newfields) => {
	const selectedId = creatorProfile.github_userid !== undefined && creatorProfile.github_userid !== null && creatorProfile.github_userid.length == 32 ? creatorProfile.github_userid : userId

    var data = newfields
    newfields["user_id"] = selectedId

    fetch(globalUrl + "/api/v1/users/updateuser", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("Status not 200 for set creator :O!");
        }

        return response.json();
      })
      .then((responseJson) => {
        if (!responseJson.success && responseJson.reason !== undefined) {
          toast("Failed updating user: " + responseJson.reason);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userEditWrapper = () => {
    var update = false
    var newfields = {
    }

    if (description.length > 0) {
      newfields["creator_description"] = description
      update = true
    }

    if (profile_url.length > 0) {
      newfields["creator_url"] = profile_url
      update = true
    }

    if (location.length > 0) {
      newfields["creator_location"] = location
      update = true
    }

    if (skills.length > 0) {
      newfields["creator_skills"] = skills
      update = true
    }

    if (workStatus.length > 0) {
      newfields["creator_work_status"] = workStatus
      update = true
    }

    if (socialLinks.length > 0) {
      newfields["creator_social"] = socialLinks
      update = true
    }

    setUser(userdata.id, newfields)
  }

  const landingpageData = (
    <div style={{}}>
      <Paper style={boxStyle}>
        {imageInfo}
        <Grid style={{ paddingRight:"1033px"}}><Button
    variant="outlined"
    style={{
    }}
    onClick={handleClick}
  >
    <ArrowBackIcon />
  </Button></Grid>
        <h2>Edit Public Profile</h2>
        <div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
          <TextField
            style={{ backgroundColor: theme.palette.inputColor, flex: "1" }}
            multiline
            rows={2}
            maxRows={4}
            color="primary"
            required
            fullWidth={true}
            defaultValue={creatorProfile.github_bio}
            placeholder="Write Description"
            type="Description"
            id="standard-required"
            autoComplete="Description"
            margin="normal"
            variant="outlined"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <Divider style={{ marginTop: "40px" }} />
        <div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
          <TextField
            style={{
              backgroundColor: theme.palette.inputColor,
              flex: "1",
              marginRight: "15px",
            }}
            InputProps={{
              style: {
                height: "50px",
                color: "white",
              },
            }}
            color="primary"
            required
            fullWidth={true}
            defaultValue={creatorProfile.github_url}
            placeholder="Profile URL"
            type="URL"
            id="standard-required"
            autoComplete="URL"
            margin="normal"
            variant="outlined"
            onChange={(e) => setProfileUrl(e.target.value)}
          />
          <TextField
            style={{
              backgroundColor: theme.palette.inputColor,
              flex: "1",
              marginLeft: "15px",
            }}
            InputProps={{
              style: {
                height: "50px",
                color: "white",
              },
            }}
            color="primary"
            required
            defaultValue={creatorProfile.github_location}
            fullWidth={true}
            placeholder="Location"
            id="standard-required"
            margin="normal"
            variant="outlined"
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <Divider style={{ marginTop: 40, marginBottom: 40, }} />
        <h2>Additional info</h2>
        <div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
          <TextField
            style={{
              backgroundColor: theme.palette.inputColor,
              flex: "1",
              marginRight: "15px",
            }}
            InputProps={{
              style: {
                height: "50px",
                color: "white",
              },
            }}
            color="primary"
            required
            defaultValue={creatorProfile.skills}
            fullWidth={true}
            placeholder="Skills"
            type="Skills"
            id="standard-required"
            autoComplete="Skills"
            margin="normal"
            variant="outlined"
            onChange={(e) => setSkills(e.target.value)}
          />
          <TextField
            style={{
              backgroundColor: theme.palette.inputColor,
              flex: "1",
              marginRight: "15px",
            }}
            InputProps={{
              style: {
                height: "50px",
                color: "white",
              },
            }}
            color="primary"
            required
            defaultValue={creatorProfile.work_status}
            fullWidth={true}
            placeholder="Work Status"
            type="Work Status"
            id="standard-required"
            autoComplete="Work Status"
            margin="normal"
            variant="outlined"
            onChange={(e) => setWorkStatus(e.target.value)}
          />
          <TextField
            style={{
              backgroundColor: theme.palette.inputColor,
              flex: "1",
              marginLeft: "15px",
            }}
            InputProps={{
              style: {
                height: "50px",
                color: "white",
              },
            }}
            color="primary"
            required
            defaultValue={creatorProfile.social}
            fullWidth={true}
            type="Social"
            placeholder="Linkedin"
            id="standard-required"
            margin="normal"
            variant="outlined"
            onChange={(e) => setSocialLinks(e.target.value)}
          />
        </div>
        <Button
          style={{ width: "100%", height: "60px", marginTop: "10px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            userEditWrapper()
          }}
        >
          Submit Profile change
        </Button>
        <h3>{passwordFormMessage}</h3>
        <Divider style={{ marginTop: "40px" }} />
        {/* TBD - Earnings! */}

      </Paper>
    </div>
  );


  const loaddata =
  <Grid> 
    {creatorProfile.banner === undefined || creatorProfile.banner === null || creatorProfile.banner.length === 0 ?
				<div style={{ height: 300, }} />
				:
				<img src={creatorProfile.banner} style={{ minWidth: 1078, maxWidth: 1200,borderRadius: theme.palette.borderRadius, height: 250, border: "1px solid grey", }} />
			}
  </Grid>

  const loadedCheck =
    isLoaded ? (
      <div style={bodyDivStyle}>
        {loaddata}
        {landingpageData}</div>
    ) : (
      <div></div>
    );

  return <div>{loadedCheck}</div>;
};
export default CreatorEdit;
