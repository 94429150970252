import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga4';
import Checkbox from '@mui/material/Checkbox';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ExtensionIcon from '@mui/icons-material/Extension';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import theme from '../theme.jsx';
import {
	Button,
  	Collapse,
		IconButton,
    FormGroup,
    FormControl,
    InputLabel,
    FormLabel,
    FormControlLabel,
    Select,
    MenuItem,
    Grid,
		Paper, 
		Typography, 
		TextField,
		Zoom,
		List,
		ListItem,
		ListItemText,
		Divider,
		Tooltip,
		Chip,
		ButtonGroup,
} from "@mui/material";
//import { useAlert 

import { useNavigate, Link } from "react-router-dom";
import WorkflowSearch from '../components/Workflowsearch.jsx';
import AuthenticationItem from '../components/AuthenticationItem.jsx';
import WorkflowPaper from "../components/WorkflowPaper.jsx"
import UsecaseSearch from "../components/UsecaseSearch.jsx"

const responsive = {
    0: { items: 1 },
};

const WelcomeForm = (props) => {
	const { userdata, globalUrl, discoveryWrapper, setDiscoveryWrapper, setActiveStep, skipped, setSkipped, getApps, apps, handleSetSearch, selectionOpen, setSelectionOpen, } = props

	var activeStep = 2
	var steps = [1,2,3]
  	const [frameworkData, setFrameworkData] = useState(undefined);
  	const [appFramework, setAppFramework] = useState(undefined);
	const usecaseButtons = [{
		"name": "Phishing",
		"usecase": "Email management",
		"color": "#C51152",
	}, {
		"name": "Enrichment",
		"usecase": "2. Enrich",
		"color": "#F4C20D",
	}, {
		"name": "Detection",
		"usecase": "3. Detect",
		"color": "#3CBA54",
	}, {
		"name": "Response",
		"usecase": "4. Respond",
		"color": "#4885ED",
	}]

	//backgroundColor: thumbIndex === usecaseButtons.length-1 ? "inherit" : "white",
	const [defaultSearch, setDefaultSearch] = React.useState("")
    const [usecaseItems, setUsecaseItems] = useState([
			{
				"search": "Phishing",
				"usecase_search": undefined,
			},
			{
				"search": "Enrichment",
				"usecase_search": undefined,
			},
			{
				"search": "Enrichment",
				"usecase_search": "SIEM alert enrichment",
			},
			{
				"search": "Build your own",
				"usecase_search": undefined,
			}])

			
    const [discoveryData, setDiscoveryData] = React.useState({})
    const [name, setName] = React.useState("")
    const [orgName, setOrgName] = React.useState("")
    const [role, setRole] = React.useState("")
    const [orgType, setOrgType] = React.useState("")
    const [finishedApps, setFinishedApps] = React.useState([])
  	const [authentication, setAuthentication] = React.useState([]);
	const [newSelectedApp, setNewSelectedApp] = React.useState({})
	const [thumbIndex, setThumbIndex] = useState(0);
    const [thumbAnimation, setThumbAnimation] = useState(false);
    const [clickdiff, setclickdiff] = useState(0);
	const isCloud = window.location.host === "localhost:3002" || window.location.host === "shuffler.io";
  
	let navigate = useNavigate();

    const onNodeSelect = (label) => {
		if (setDiscoveryWrapper !== undefined) {
			setDiscoveryWrapper(
				{"id": label}
			)
		}

		if (isCloud) {
			ReactGA.event({
				category: "welcome",
				action: `click_${label}`,
				label: "",
			})
		}

		setSelectionOpen(true)
		setDefaultSearch(label)
    }

    useEffect(() => {
			if (userdata.id === undefined) {
				return
			}

			if (userdata.name !== undefined && userdata.name !== null && userdata.name.length > 0) {
				setName(userdata.name)
			}

			if (userdata.active_org !== undefined && userdata.active_org.name !== undefined && userdata.active_org.name !== null && userdata.active_org.name.length > 0) {
				setOrgName(userdata.active_org.name)
			}
		}, [userdata])

		useEffect(() => {
			if (discoveryWrapper === undefined || discoveryWrapper.id === undefined) {
				setDefaultSearch("")
				var newfinishedApps = finishedApps
				newfinishedApps.push(defaultSearch)
				setFinishedApps(finishedApps)
			}
		}, [discoveryWrapper])

		const getFramework = () => {
			fetch(globalUrl + "/api/v1/apps/frameworkConfiguration", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				credentials: "include",
			})
			.then((response) => {
				if (response.status !== 200) {
					console.log("Status not 200 for framework!");
				}

				return response.json();
			})
			.then((responseJson) => {
				if (responseJson.success === false) {
					setAppFramework({})

					if (responseJson.reason !== undefined) {
						//toast("Failed loading: " + responseJson.reason)
					} else {
						//toast("Failed to load framework for your org.")
					}
				} else {
					setAppFramework(responseJson)
				}
			})
			.catch((error) => {
				console.log("err in framework: ", error.toString());
			})
		}

		useEffect(() => {
			getFramework() 


			if (
				window.location.search !== undefined &&
				window.location.search !== null
			) {
				const urlSearchParams = new URLSearchParams(window.location.search);
				const params = Object.fromEntries(urlSearchParams.entries());
				const foundTab = params["tab"];
				if (foundTab !== null && foundTab !== undefined && !isNaN(foundTab)) {
					if (foundTab === 3 || foundTab === "3") {
						//console.log("Set search!")
					}
				} else { 
    			//navigate(`/welcome?tab=1`)
				}

				const foundTemplate = params["workflow_template"];
				if (foundTemplate !== null && foundTemplate !== undefined) {
					console.log("Found workflow template: ", foundTemplate)
				
					var sourceapp = undefined
					var destinationapp = undefined
					var action = undefined
					const srcapp = params["source_app"];
					if (srcapp !== null && srcapp !== undefined) {
						sourceapp = srcapp
					}

					const dstapp = params["dest_app"];
					if (dstapp !== null && dstapp !== undefined) {
						destinationapp = dstapp
					}

					const act = params["action"];
					if (act !== null && act !== undefined) {
						action = act
					}
    
					//defaultSearch={foundTemplate}
					//
					usecaseItems[0] = {
						"search": "enrichment",
						"usecase_search": foundTemplate,
						"sourceapp": sourceapp,
						"destinationapp": destinationapp,
						"autotry": action === "try",
					}

					console.log("Adding: ", usecaseItems[0])

					setUsecaseItems(usecaseItems)
				}
			}
		}, [])

    const isStepOptional = step => {
        return step === 1
    }

		const sendUserUpdate = (name, role, userId) => {
			const data = {
				"tutorial": "welcome",
				"firstname": name,
				"company_role": role,
				"user_id": userId,
			}

			const url = `${globalUrl}/api/v1/users/updateuser`
			fetch(url, {
				mode: "cors",
				method: "PUT",
				body: JSON.stringify(data),
				credentials: "include",
				crossDomain: true,
				withCredentials: true,
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				},
			})
			.then((response) =>
				response.json().then((responseJson) => {
					if (responseJson["success"] === false) {
						console.log("Update user success")
						//toast("Failed updating org: ", responseJson.reason);
					} else {
						console.log("Update success!")
						//toast("Successfully edited org!");
					}
				})
			)
			.catch((error) => {
				console.log("Update err: ", error.toString())
				//toast("Err: " + error.toString());
			});
		}

		const sendOrgUpdate = (orgname, company_type, orgId, priority) => {
			var data = {
				org_id: orgId,
			};

			if (orgname.length > 0) {
				data.name = orgname
			}

			if (company_type.length > 0) {
				data.company_type = company_type 
			}

			if (priority.length > 0) {
				data.priority = priority
			}

			const url = globalUrl + `/api/v1/orgs/${orgId}`;
			fetch(url, {
				mode: "cors",
				method: "POST",
				body: JSON.stringify(data),
				credentials: "include",
				crossDomain: true,
				withCredentials: true,
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				},
			})
			.then((response) =>
				response.json().then((responseJson) => {
					if (responseJson["success"] === false) {
						console.log("Update of org failed")
						//toast("Failed updating org: ", responseJson.reason);
					} else {
						//toast("Successfully edited org!");
					}
				})
			)
			.catch((error) => {
				console.log("Update err: ", error.toString())
				//toast("Err: " + error.toString());
			});
		}

		var workflowDelay = -50
		const NewHits = ({ hits }) => {
			const [mouseHoverIndex, setMouseHoverIndex] = useState(-1) 
			var counted = 0

			const paperAppContainer = {
					display: "flex",
					flexWrap: "wrap",
					alignContent: "space-between",
					marginTop: 5,
  		}

			return (
				<Grid container spacing={4} style={paperAppContainer}>
					{hits.map((data, index) => {
						workflowDelay += 50

						if (index > 3) {
							console.log("Skipping: ", index)
							return null
						}

						return (
							<Zoom key={index} in={true} style={{ transitionDelay: `${workflowDelay}ms` }}>
								<Grid item xs={6} style={{ padding: "12px 10px 12px 10px" }}>
									<WorkflowPaper key={index} data={data} />
								</Grid>
							</Zoom>
						)
					})}
				</Grid>
			)
		}

    const isStepSkipped = step => {
        return skipped.has(step)
    }

    const handleNext = () => {
		setDefaultSearch("")

		if (activeStep === 0) {
			console.log("Should send basic information about org (fetch)")
			setclickdiff(240)
			navigate(`/welcome?tab=2`)
									
			if (isCloud) {
				ReactGA.event({
						category: "welcome",
						action: "click_page_one_next",
						label: "",
				})
			}

			if (userdata.active_org !== undefined && userdata.active_org.id !== undefined && userdata.active_org.id !== null && userdata.active_org.id.length > 0) {
				sendOrgUpdate(orgName, orgType, userdata.active_org.id, "") 
			}

			if (userdata.id !== undefined && userdata.id !== null && userdata.id.length > 0) {
				sendUserUpdate(name, role, userdata.id) 
			}

		} else if (activeStep === 1) {
			console.log("Should send secondary info about apps and other things")
			setDiscoveryWrapper({})

			navigate(`/welcome?tab=3`)
			//handleSetSearch("Enrichment", "2. Enrich")
			handleSetSearch(usecaseButtons[0].name, usecaseButtons[0].usecase)
			getApps()

			// Make sure it's up to date
			if (getFramework !== undefined) {
				getFramework()
			}
		} else if (activeStep === 2) {
			console.log("Should send third page with workflows activated and the like")
		}


        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);

				if (activeStep === 2) {
					setDiscoveryWrapper({})

					if (getFramework !== undefined) {
						getFramework()
					}
					navigate("/welcome?tab=2")
				} else if (activeStep === 1) {
					navigate("/welcome?tab=1")
				}
    };

    const handleSkip = () => {
		setclickdiff(240)
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        console.log("Selected app changed (effect)")
    }, [newSelectedApp])

	//const buttonWidth = 145 
	const buttonWidth = 450 
	const buttonMargin = 10
	const sizing = 475
	const buttonStyle = {
		flex: 1,
		width: "100%", 
		padding: 25,
		margin: buttonMargin,
		fontSize: 18,
	}

	const slideNext = () => {
		if (!thumbAnimation && thumbIndex < usecaseItems.length - 1) {
			//handleSetSearch(usecaseButtons[0].name, usecaseButtons[0].usecase)
			setThumbIndex(thumbIndex + 1);
		} else if (!thumbAnimation && thumbIndex === usecaseItems.length - 1) {
			setThumbIndex(0)
		}
    };

    const slidePrev = () => {
		if (!thumbAnimation && thumbIndex > 0) {
			setThumbIndex(thumbIndex - 1);
		} else if (!thumbAnimation && thumbIndex === 0) {
			setThumbIndex(usecaseItems.length-1)
		}
    };

	const newButtonStyle = {
		padding: 22, 
		flex: 1, 
		margin: buttonMargin,
		minWidth: buttonWidth, 
		maxWidth: buttonWidth, 
	}


	const formattedCarousel = appFramework === undefined || appFramework === null ? [] : usecaseItems.map((item, index) => {
		console.log("Item: ", item)
		return (
			<div style={{minWidth: "95%", maxWidth: "95%", marginLeft: 5, marginRight: 5, }}>
				<UsecaseSearch
					globalUrl={globalUrl}
					defaultSearch={item.search}
					usecaseSearch={item.usecase_search}
					appFramework={appFramework}
					apps={apps}
					getFramework={getFramework}
					userdata={userdata}
					autotry={item.autotry}
					sourceapp={item.sourceapp}
					destinationapp={item.destinationapp}
				/>
			</div>
		)
	})

	console.log("CAROUSEL: ", formattedCarousel)

    const getStepContent = (step) => {
			return (
				<Collapse in={true}>
                    <div style={{maxWidth: 700, minWidth: 700, margin: "auto", minHeight: sizing, maxHeight: sizing, }}>
						<Typography variant="body1" style={{marginBottom: 0, maxWidth: 500, margin: "auto", marginBottom: 15, }} color="textSecondary">
							These are some of our Workflow templates, used to start new Workflows. Use the right and left buttons to find <a href="/usecases" target="_blank" rel="norefferer" style={{color: "#f86a3e", textDecoration: "none", }}>new Usecases</a>, and click the orange button to build it.
						</Typography>

						<div style={{marginTop: 0, }}>
						<div className="thumbs" style={{display: "flex"}}>
							<Tooltip title={"Previous usecase"}>
								<IconButton
									style={{
										backgroundColor: thumbIndex === 0 ? "inherit" : "white",
										zIndex: 5000,
										minHeight: 50, 
										maxHeight: 50, 
										color: "grey",
										marginTop: 150,
										borderRadius: 50,
										border: "1px solid rgba(255,255,255,0.3)",
									}}
									onClick={() => {
										slidePrev() 
									}}
								>
									<ArrowBackIosNewIcon />
								</IconButton>
							</Tooltip>
							<div style={{minWidth: 554, maxWidth: 554, borderRadius: theme.palette.borderRadius, padding: 25, }}>
								<AliceCarousel
									style={{ backgroundColor: theme.palette.surfaceColor, minHeight: 750, maxHeight: 750, }}
									items={formattedCarousel}
									activeIndex={thumbIndex}
									infiniteLoop
									mouseTracking={false}
									responsive={responsive}
									// activeIndex={activeIndex}
									controlsStrategy="responsive"
									autoPlay={false}
									infinite={true}
									animationType="fadeout"
									animationDuration={800}
									disableButtonsControls
								/>
							</div>
							<Tooltip title={"Next usecase"}>
								<IconButton
									style={{
										backgroundColor: thumbIndex === usecaseButtons.length-1 ? "inherit" : "white",
											zIndex: 5000,
											minHeight: 50, 
											maxHeight: 50, 
											color: "grey",
											marginTop: 150,
											borderRadius: 50,
											border: "1px solid rgba(255,255,255,0.3)",
										}}
										onClick={() => {
											slideNext() 
										}}
									>
										<ArrowForwardIosIcon />
									</IconButton>
								</Tooltip>
					</div>
						</div>
				</div>
			</Collapse>
		)
    }

	const extraHeight = isCloud ? -7 : 0
    return (
        <div style={{}}>
			<div>
                {activeStep === steps.length ? (
                    <div paddingTop="20px">
                        You Will be Redirected to getting Start Page Wait for 5-sec.
                        <Button onClick={handleReset}>Reset</Button>
                        <script>
                            setTimeout(function() {
                            	navigate("/workflows")
							}, 5000);
                        </script>
                        <Button>
						<Link style={{color: "#f86a3e", }} to="/workflows" className="btn btn-primary">
							Getting Started
						</Link>
					</Button>
				</div>
                ) : (
				<div style={{marginTop: 100, }}>
					{getStepContent(activeStep)}
			  	</div>
              )}
            </div>
        </div>
    );
}

export default WelcomeForm 
