import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Grid, Container } from '@mui/material';
import theme from '../theme.jsx';
import React, { useState, useEffect } from 'react';
import {
    Typography,
} from '@mui/material';
import LogoItems from "../components/LogoItems.jsx"; 

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 5 },
};
		
//<PartnerItems partnerLogo="images/partner/partners/logo-cogital.png" partnerText="Subsidiary of the Neo-Soft Group, Cogital is an expert in cybersecurity. Do you need to deploy solutions to protect your information system?" partnerUrl="https://www.cogital-it.com/" partnerName="Cogital" />,
const LogoList = (props) => {
	const handleDragStart = (e) => e.preventDefault();
	//if you add more partner Text it will hide partnerurl so make sure to mation height for partner text
	const items=[
		<LogoItems companyLogo="images/companies_logo/WithSecure_logo.svg" partnerName="WithSecure" style={{ color: "white", margin: "15px auto 0px auto", justifyContent: "center", textAlign: "center", height: 20, maxHeight: 20, minHeight: 20, }} />,
		<LogoItems companyLogo="images/companies_logo/VMWare_logo.svg" partnerName="VMWare" style={{ margin: "15px auto 0px auto", justifyContent: "center", textAlign: "center", height: 25, maxHeight: 25, minHeight: 25, }} />,
		<LogoItems companyLogo="images/companies_logo/DHL_Logo.svg" partnerName="DHL" style={{ margin: "15px auto 0px auto", justifyContent: "center", textAlign: "center", height: 30, maxHeight: 30, minHeight: 30, }}  />,
		<LogoItems companyLogo="images/companies_logo/Group 14969.svg" partnerName="thyssenkrupp"  style={{ margin: "15px auto 0px auto", justifyContent: "center", textAlign: "center", height: 50, maxHeight: 50, minHeight: 50, }} />,
		<LogoItems companyLogo="images/companies_logo/Group 14970.svg" partnerName="ERICSSON"  style={{ margin: "15px auto 0px auto", justifyContent: "center", textAlign: "center", height: 50, maxHeight: 50, minHeight: 50, }} />,
		<LogoItems companyLogo="images/companies_logo/NIO_logo.svg" partnerName="NIO"  style={{ margin: "15px auto 0px auto", justifyContent: "center", textAlign: "center", height: 50, maxHeight: 50, minHeight: 50, }} />,
	]   

	return (
			<Grid style={{ }}>
					<Typography variant="body1" style={{ paddingTop: 24, fontSize: 16, fontWeight: 600,fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", marginBottom: 36, textAlign: "center", }}>
                        Join the companies that already use Shuffle
					</Typography>
					<div style={{ height: "250px", position: "relative", }} >
						<AliceCarousel
								style={{ backgroundColor: theme.palette.surfaceColor }}
								items={items}
								infiniteLoop
								mouseTracking
								responsive={responsive}
								// activeIndex={activeIndex}
								controlsStrategy="responsive"
								autoPlay={true}
								autoPlayInterval={2000}
								infinite={true}
								disableButtonsControls
								onClick={(e) => {
									console.log("Click: ", e.target)
								}}
						/>
					</div>
			</Grid>
		);
};

export default LogoList; 
