import React, { useState } from 'react';
//import { useAlert
import { ToastContainer, toast } from "react-toastify" 
import { BrowserView, MobileView } from "react-device-detect";
import { 
	Paper, 
	Button, 
	Typography, 
	TextField,
	Box,
	Autocomplete,
} from '@mui/material';
import countries from "../components/Countries.jsx";
import { useTheme } from "@mui/styles";

const bodyDivStyle = {
	margin: "auto",
	textAlign: "center",
	width: 768,
}

// Should be different if logged in :|
const ContactPartner = (props) => {
	const [emailError, setEmailError] = useState('')
	const { globalUrl, surfaceColor, newColor, mini, textColor, inputColor } = props;
	//const alert = useAlert();
	const theme = useTheme();
	const [dealCountry, setDealCountry] = React.useState("United States");
	const [dealType, setDealType] = React.useState("Authorized Partner");

	const products = [
		{ code: '', label: 'Authorized Partner', phone: '' },
		{ code: '', label: 'Silver Partner', phone: '' },
		{ code: '', label: 'Gold Partner', phone: '' },
	]

	const minimize = mini !== undefined && mini
	const textcolor = textColor !== undefined ? textColor : "#ffffff"
	const inputcolor = newColor !== undefined ? newColor : inputColor

	const boxStyle = {
		flex: "1",
		marginLeft: "10px",
		marginRight: "10px",
		paddingLeft: "30px",
		paddingRight: "30px",
		paddingBottom: "30px",
		paddingTop: "30px",
		backgroundColor: surfaceColor,
		display: "flex",
		flexDirection: "column"
	}

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [companyname, setCompanyname] = useState("");
	const [email, setEmail] = useState("");
	const [validateEmail, setValidateEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");

	const [formMessage, setFormMessage] = useState("");

	const submitContact = () => {	
		const data = {
			"firstname": firstname,
			"lastname": lastname,
			"companyname": companyname,
			"email": email,
			"validate_email": validateEmail,
			"phone": phone,
			"message": message,
			"dealtype": dealType['label'],
			"dealcountry": dealCountry
		}
		console.log(data)

		fetch(globalUrl + "/api/v1/contactpartner", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(response => response.json())
			.then(response => {
				if (response.success === true) {
					setFormMessage(response.message)
					toast("Thanks for submitting!")
					setMessage("")
					//setEmail("")
					//setName("")
				} else {
					const msg = "Something went wrong. Please contact support@shuffler.io directly."
					if (response.reason !== undefined && response.reason !== null) {
						setFormMessage(response.reason)
					} else {
						setFormMessage(msg)
						toast(msg)
					}
				}
			})
			.catch(error => {
				console.log(error)
			});
	}

	// Random names for type & autoComplete. Didn't research :^)
	const landingpageDataBrowser =
		<div>
			<div style={{ paddingTop: 100 }} />
			<div style={{ display: "flex", marginTop: 15, }}>
				<Paper elevation={mini === true ? 0 : 0} style={boxStyle}>
					<Typography variant="h6" style={{ color: "white", marginTop: 10, marginBottom: 10, }}>Become a partner</Typography>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", marginRight: "15px", backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="First Name"
							label="First Name"
							type="firstname"
							id="standard-required"
							autoComplete="firstname"
							margin="normal"
							variant="outlined"
							onChange={e => setFirstname(e.target.value)}
						/>
						<TextField
							style={{ flex: "1", marginLeft: "15px", backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Last Name"
							label="Last Name"
							type="lastname"
							id="standard"
							autoComplete="lastname"
							margin="normal"
							variant="outlined"
							onChange={e => setLastname(e.target.value)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="mail@company.com"
							label="Business email"
							type="email"
							id={minimize ? "email_field_contact_mini" : "email_field_contact"}
							autoComplete="email"
							margin="normal"
							variant="outlined"
							onChange={(e) => setEmail(e.target.value)}
						/>{emailError}
						<TextField
							required
							style={{ flex: "1", marginLeft: 25, backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Email Confirmation"
							type="email"
							label="Confirm your email"
							id={minimize ? "email_field_contact_mini" : "email_field_contact"}
							autoComplete="email"
							margin="normal"
							variant="outlined"
							onChange={e => setValidateEmail(e.target.value)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							style={{ flex: "1", backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							type="companyname"
							placeholder="Company Name"
							id="standard-required"
							label="Company Name"
							autoComplete="companyname"
							margin="normal"
							variant="outlined"
							onChange={e => setCompanyname(e.target.value)}
						/>
						<TextField
							style={{ flex: "1", marginLeft: 25, backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							type="phone"
							placeholder="Phone Number"
							label="Phone Number"
							id="standard-required"
							autoComplete="phone"
							margin="normal"
							variant="outlined"
							onChange={e => setPhone(e.target.value)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row", marginTop: 10, marginBottom: 10 , alignItems:"center"}}>
						<Autocomplete
							id="country-select"
							sx={{ width: 331}}
							options={countries}
							variant="outlined"
							margin="normal"
							autoHighlight
							getOptionLabel={(option) => option.label}
							onChange={(event, newValue) => {
								setDealCountry(newValue.label)
							}}
							renderOption={(props, option) => (
								<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									<img
										loading="lazy"
										width="20"
										src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
										srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
										alt=""
									/>
									{option.label} ({option.code}) +{option.phone}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									style={{ flex: "1", backgroundColor: inputcolor }}
									variant="outlined"
									label="Choose a country"
									defaultValue={dealCountry}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
								/>
							)}
						/>
						<Autocomplete
							id="product-select"
							sx={{ width: 331 }}
							options={products}
							variant="outlined"
							margin="normal"
							autoHighlight
							onChange={(event, newValue) => {
								setDealType(newValue)
							}}
							getOptionLabel={(option) => option.label}
							renderOption={(props, option) => (
								<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									{option.label}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									style={{ flex: "1", marginLeft: 25, backgroundColor: inputcolor }}
									variant="outlined"
									label="Choose a plan"
									defaultValue={dealType}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
								/>
							)}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: "white", marginTop: 10, marginBottom: 10, }}>Message</Typography>
					</div>
					<div style={{ flex: 4 }}>
						<TextField
							multiline
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							style={{ flex: "1", backgroundColor: inputcolor }}
							rows="6"
							fullWidth={true}
							placeholder="What can we help you with?"
							type="message"
							id="filled-multiline-static"
							margin="normal"
							variant="outlined"
							onChange={e => setMessage(e.target.value)}
						/>
					</div>
					<Button
						disabled={email.length <= 0 || message.length <= 0}
						style={{ width: "100%", height: "60px", marginTop: "10px" }}
						variant="contained"
						color="primary"
						onClick={submitContact}
					>
						Submit
					</Button>
					<Typography variant="body1" style={{ color: "white", marginTop: 10, whiteSpace: 'pre-line'}}>
						{formMessage}
					</Typography>
				</Paper>
			</div>
		</div>

	const landingpageDataMobile =
	<div>
			<div style={{paddingTop: 100, paddingBottom: "50px"}}>
				<Paper elevation={mini === true ? 0 : 0} style={boxStyle}>
					<Typography variant="h6" style={{ color: "white", marginTop: 10, marginBottom: 10, alignItems:"center" }}>Become a partner</Typography>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", marginRight: "15px", backgroundColor: inputcolor}}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="First Name"
							label="First Name"
							type="firstname"
							id="standard-required"
							autoComplete="firstname"
							margin="normal"
							variant="outlined"
							onChange={e => setFirstname(e.target.value)}
						/>
						<TextField
							style={{ flex: "1", marginLeft: "15px", backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Last Name"
							label="Last Name"
							type="lastname"
							id="standard"
							autoComplete="lastname"
							margin="normal"
							variant="outlined"
							onChange={e => setLastname(e.target.value)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="mail@company.com"
							label="Business email"
							type="email"
							id={minimize ? "email_field_contact_mini" : "email_field_contact"}
							autoComplete="email"
							margin="normal"
							variant="outlined"
							onChange={(e) => setEmail(e.target.value)}
						/>{emailError}
						<TextField
							required
							style={{ flex: "1", marginLeft: 25, backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Email Confirmation"
							type="email"
							label="Confirm your email"
							id={minimize ? "email_field_contact_mini" : "email_field_contact"}
							autoComplete="email"
							margin="normal"
							variant="outlined"
							onChange={e => setValidateEmail(e.target.value)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							style={{ flex: "1", backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							type="companyname"
							placeholder="Company Name"
							id="standard-required"
							label="Company Name"
							autoComplete="companyname"
							margin="normal"
							variant="outlined"
							onChange={e => setCompanyname(e.target.value)}
						/>
						<TextField
							style={{ flex: "1", marginLeft: 25, backgroundColor: inputcolor }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							type="phone"
							placeholder="Phone Number"
							label="Phone Number"
							id="standard-required"
							autoComplete="phone"
							margin="normal"
							variant="outlined"
							onChange={e => setPhone(e.target.value)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row", marginTop: 10, marginBottom: 10,fontSize: "13px"  }}>
						<Autocomplete
							fullWidth={true}
							id= {minimize ? "country-select":"country-select"}
							sx={{ width: 650 }}
							options={countries}
							variant="outlined"
							margin="normal"
							autoHighlight
							getOptionLabel={(option) => option.label}
							onChange={(event, newValue) => {
								setDealCountry(newValue.label)
							}}
							renderOption={(props, option) => (
								<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									<img
										loading="lazy"
										width="20"
										src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
										srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
										alt=""
									/>
									{option.label} ({option.code}) +{option.phone}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									style={{ flex: "1", backgroundColor: inputcolor }}
									variant="outlined"
									label="Choose a country"
									defaultValue={dealCountry}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
								/>
							)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row", marginTop: 10, marginBottom: 10,fontSize: "13px"}}  >
					<Autocomplete
							fullWidth={true}
							id="product-select"
							sx={{ width: 650 }}
							options={products}
							variant="outlined"
							margin="normal"
							autoHighlight
							onChange={(event, newValue) => {
								setDealType(newValue)
							}}
							getOptionLabel={(option) => option.label}
							renderOption={(props, option) => (
								<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									{option.label}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									style={{ flex: "1", backgroundColor: inputcolor }}
									variant="outlined"
									label="Choose a plan"
									defaultValue={dealType}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
								/>
							)}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: "white", marginTop: 10, marginBottom: 10, }}>Message</Typography>
					</div>
					<div style={{ flex: 4 }}>
						<TextField
							multiline
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							style={{ flex: "1", backgroundColor: inputcolor }}
							rows="6"
							fullWidth={true}
							placeholder="What can we help you with?"
							type="message"
							id="filled-multiline-static"
							margin="normal"
							variant="outlined"
							onChange={e => setMessage(e.target.value)}
						/>
					</div>
					<Button
						disabled={email.length <= 0 || message.length <= 0}
						style={{ width: "100%", height: "60px", marginTop: "10px" }}
						variant="contained"
						color="primary"
						onClick={submitContact}
					>
						Submit
					</Button>
					<Typography variant="body1" style={{ color: "white", marginTop: 10, whiteSpace: 'pre-line'}}>
						{formMessage}
					</Typography>
				</Paper>
			</div>
		</div>
		// <div style={{ paddingBottom: "50px" }}>
		// 	{minimize ?
		// 		<div style={{ color: textcolor, textAlign: "center" }}>
		// 			<Typography variant="h4" style={{ marginTop: 15, }}>Contact us</Typography>
		// 		</div>
		// 		:
		// 		<div style={{ color: textcolor, textAlign: "center" }}>
		// 			<h3 style={{}}>Contact us</h3>
		// 			<h2>What can we do for you?</h2>
		// 		</div>}
		// 	<div style={{ display: "flex", marginTop: 10, }}>
		// 		<Paper elevation={mini === true ? 0 : 0} style={boxStyle}>
		// 			<Typography variant="h6" style={{ color: textcolor }}>Contact Details</Typography>
		// 			<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
		// 				<TextField
		// 					required
		// 					style={{ flex: "1", backgroundColor: inputcolor }}
		// 					InputProps={{
		// 						style: {
		// 							color: textcolor,
		// 						},
		// 					}}
		// 					color="primary"
		// 					fullWidth={true}
		// 					placeholder="Name"
		// 					type="firstname"
		// 					id="firstname"
		// 					autoComplete="firstname"
		// 					margin="normal"
		// 					variant="outlined"
		// 					onChange={e => setFirstname(e.target.value)}
		// 				/>
		// 			</div>
		// 			<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
		// 				<TextField
		// 					required
		// 					style={{ flex: "1", backgroundColor: inputcolor }}
		// 					InputProps={{
		// 						style: {
		// 							color: textcolor,
		// 						},
		// 					}}
		// 					color="primary"
		// 					fullWidth={true}
		// 					placeholder="Email"
		// 					type="email"
		// 					id="email-field-contact"
		// 					autoComplete="email"
		// 					margin="normal"
		// 					variant="outlined"
		// 					onChange={e => setEmail(e.target.value)}
		// 				/>
		// 			</div>
		// 			<div style={{ flex: 1, marginTop: 20, }}>
		// 				<Typography variant="h6" style={{ color: textcolor }}>Message</Typography>
		// 			</div>
		// 			<div style={{ flex: 4 }}>
		// 				<TextField
		// 					multiline
		// 					style={{ flex: "1", backgroundColor: inputcolor }}
		// 					InputProps={{
		// 						style: {
		// 							color: textcolor,
		// 						},
		// 					}}
		// 					color="primary"
		// 					rows="6"
		// 					fullWidth={true}
		// 					placeholder="What can we help you with?"
		// 					id="filled-multiline-static"
		// 					type="message"
		// 					margin="normal"
		// 					variant="outlined"
		// 					onChange={e => setMessage(e.target.value)}
		// 				/>
		// 			</div>
		// 			<Button
		// 				disabled={email.length <= 0 || message.length <= 0}
		// 				style={{ width: "100%", height: "60px", marginTop: "10px" }}
		// 				variant="contained"
		// 				color="primary"
		// 				onClick={submitContact}
		// 			>
		// 				Submit
		// 			</Button>
		// 			<h3>{formMessage}</h3>
		// 		</Paper>
		// 	</div>
		// </div>


	const loadedCheck =
		<div>
			{minimize ?
				landingpageDataMobile
				:
				<div id="contact_form_partner" style={{ paddingBottom: 250 }}>
					<BrowserView>
						<div style={bodyDivStyle}>{landingpageDataBrowser}</div>
					</BrowserView>
					<MobileView>
						{landingpageDataMobile}
					</MobileView>
				</div>
			}
		</div>

	return (
		<div>
			{loadedCheck}
		</div>
	)
}

export default ContactPartner; 
