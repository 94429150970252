import React, {useState, useEffect} from 'react';
import { useTheme } from '@mui/styles';
//import { useAlert
import { ToastContainer, toast } from "react-toastify" 

import {Typography, Paper, Button, TextField} from '@mui/material';

const bodyDivStyle = {
	margin: "auto",
	textAlign: "center",
	width: "768px",
}

const InviteHandler = (props) => {
  const { globalUrl, isLoaded, } = props;
	//const alert = useAlert()
	const theme = useTheme()

	const boxStyle = {
		flex: "1",
		marginLeft: "10px",
		marginRight: "10px",
		paddingLeft: "30px",
		paddingRight: "30px",
		paddingBottom: "30px",
		paddingTop: "30px",
		backgroundColor: theme.palette.surfaceColor,
		display: "flex", 
		flexDirection: "column",
		color: "white",
	}

	const [data, setData] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPassword2, setNewPassword2] = useState("");
	const [passwordFormMessage, setPasswordFormMessage] = useState("");
	const [firstRequest, setFirstRequest] = useState(true)

	const getOrgInvite = (inviteId) => {
		const url = globalUrl+'/api/v1/orgs/invite/'+inviteId;
		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
		})
		.then((response) => {
			if (response.status !== 200) {
				toast("Bad reply from org")		
			}

			return response.json()
		})
    .then((responseJson) => {
			console.log("DATA: ", responseJson)
			if (responseJson.success === false) {
				setData({
					"success": false,
				})
				setPasswordFormMessage("Something went wrong - is your invite real?")
			} else {
				setData(responseJson)
			}
		})
		.catch(error => {
			console.log("Error: ", error)
			setPasswordFormMessage("Something went wrong in org invite.")
		});
	}


	const cursearch = typeof window === 'undefined' || window.location === undefined ? "" : window.location.search
	const tmpView = new URLSearchParams(cursearch).get("invite_id")
	if (firstRequest) {
		setFirstRequest(false)

		if (tmpView !== undefined && tmpView !== null && tmpView.length > 0) {
			getOrgInvite(tmpView)
		}
	}

	const onOrgAccept = (inviteId) => {
		if (inviteId === undefined) {
			setPasswordFormMessage("Something went wrong: No inviteId")
			return
		}
		
		setPasswordFormMessage("")
		const toBeSent = {"reference": inviteId, "org_id": data.org_id}
		const url = globalUrl+'/api/v1/orgs/invite/'+inviteId+"/accept";
		fetch(url, {
			mode: 'cors',
			method: 'POST',
			body: JSON.stringify(data),
			credentials: 'include',
			crossDomain: true,
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
		})
		.then((response) => {
			if (response.status !== 200) {
				toast("Bad reply from org")		
			}

			return response.json()
		})
    .then((responseJson) => {
			console.log("DATA: ", responseJson)
			if (responseJson.success === false) {
				setPasswordFormMessage("Something went wrong in acceptance?")
			} else {
				//setData(responseJson)

				if (responseJson.password_reset !== undefined && responseJson.password_reset !== null && responseJson.password_reset.length > 0){
					setPasswordFormMessage("Success! Redirecting to password setup")
					setTimeout(() => {
						window.location.pathname = "/passwordreset/"+responseJson.password_reset
					}, 3000)
				} else {
					setPasswordFormMessage("Success! Redirecting to login within your new org.")
					setTimeout(() => {
						window.location.pathname = "/login"
					}, 3000)
				}
			}
		})
		.catch(error => {
			console.log("Error: ", error)
			setPasswordFormMessage("Something went wrong.")
		});
	}

	// Random names for type & autoComplete. Didn't research :^)
	const landingpageData = 
			<div style={{display: "flex", marginTop: "80px"}}>
				<Paper style={boxStyle}>
				<Typography variant="h6">Join the Organization!</Typography>
				<Typography variant="body2" color="textSecondary">You've been invited to join the organization {data.org_name}. Ignore this invitation if you don't wish to join.</Typography>
				{data.set_password === true ?
					<div style={{flex: "1", display: "flex", flexDirection: "column", color: "white",}}>
						<TextField
							required
							fullWidth={true}
							style={{backgroundColor: theme.palette.inputColor}}
							InputProps={{
								style:{
									height: "50px", 
									color: "white",
									fontSize: "1em",
								},
							}}
							placeholder="New password"
							type="password"
							id="standard-required"
							autoComplete="password"
							margin="normal"
							variant="outlined"
							onChange={e => setNewPassword(e.target.value)}
						/>
						<TextField
							required
							fullWidth={true}
							style={{backgroundColor: theme.palette.inputColor}}
							InputProps={{
								style:{
									height: "50px", 
									color: "white",
									fontSize: "1em",
								},
							}}
							type="password"
							placeholder="Repeat new password"
							id="standard-required"
							margin="normal"
							variant="outlined"
							onChange={e => setNewPassword2(e.target.value)}
						/>
					</div>
				: null}
				<Button
					style={{width: "100%", height: 50, marginTop: 20, }}
					variant="contained"
					color="primary"
					disabled={data.success === false}
					onClick={() => {
						//onPasswordChange()
						onOrgAccept(tmpView) 
						console.log("SUBMIT!")
					}}
				>
					Accept invitation
				</Button>
				<Typography variant="body2" style={{marginTop: 15}}>{passwordFormMessage}</Typography>
			</Paper>
		</div>

const loadedCheck = isLoaded ? 
	<div style={bodyDivStyle}>
		{landingpageData}
	</div>
	:
	<div>
	</div>

return(
	<div>
		{loadedCheck}
	</div>
)
}
export default InviteHandler;
